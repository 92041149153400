import React, { FC } from "react"

import { useLogin } from "../../../hooks/useLogin"

import { Header, PageWrapper } from "../../../../../components/Wavix"
import { Warning } from "../../../components/Warning"
import { NewPasswordField } from "../../components/NewPasswordField"
import { PasswordConfirmationField } from "../../components/PasswordConfirmationField"
import { StyledButton } from "./NewPasswordView.parts"

export const NewPasswordView: FC = () => {
  const { onSaveNewPassword } = useLogin()

  return (
    <PageWrapper>
      <Warning />
      <Header>Restore password</Header>
      <form>
        <NewPasswordField />
        <PasswordConfirmationField />
        <StyledButton type="submit" onClick={onSaveNewPassword}>
          Save
        </StyledButton>
      </form>
    </PageWrapper>
  )
}
