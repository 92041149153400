import React, { FC } from "react"

import { useLogin } from "../../../hooks/useLogin"

import { Button, Header, PageWrapper, ActionsWrapper } from "../../../../../components/Voiso"
import { Warning } from "../../../components/Warning"
import { Add2FAViewWrapper, Description, InfoPanel, StyledQrCode } from "./Add2FAView.parts"

export const Add2FAView: FC = () => {
  const { onAdd2FASubmit, onAdd2FACancel } = useLogin()

  return (
    <PageWrapper isWide>
      <Add2FAViewWrapper>
        <InfoPanel>
          <Warning />
          <Header>Two-factor authentication</Header>

          <Description>
            Download and install Google Authenticator on your mobile device. Add your Voiso account to the app by
            scanning the QR code.
          </Description>

          <ActionsWrapper>
            <Button type="submit" onClick={onAdd2FASubmit}>
              Continue
            </Button>
            <Button variant="secondary" type="button" onClick={onAdd2FACancel}>
              Back
            </Button>
          </ActionsWrapper>
        </InfoPanel>

        <StyledQrCode />
      </Add2FAViewWrapper>
    </PageWrapper>
  )
}
