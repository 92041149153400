import styled from "styled-components"

export const PageWrapper = styled.div<{ isWide?: boolean }>`
  display: flex;
  flex-direction: column;
  width: ${({ isWide }) => (isWide ? "635px" : "400px")};
  margin: 12% 0% 0% ${({ isWide }) => (isWide ? "25%" : "33%")};
`

export const Header = styled.div`
  font-size: 28px;
  line-height: 30px;
  margin-bottom: 25px;
`

export const FormTextInput = styled.input`
  outline: none;

  font-size: 18px;
  width: 400px;
  height: 45px;
  border: none;
  border-bottom: 2px solid #fff;
  background: none;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  margin-bottom: 20px;
  -webkit-text-fill-color: #fff;
  -webkit-background-clip: text !important;
  background-clip: text;
  font-family: Helvetica Neue;
  color: #fff;
  &::-webkit-input-placeholder {
    font-weight: 300;
    color: #fff;
  }
`

export const FormFieldErrorMessage = styled.div`
  width: 400px;
  color: #bf080b;
  font-size: 14px;
  font-weight: normal;
`

export const ActionsWrapper = styled.div`
  display: flex;
  gap: 20px;
`
