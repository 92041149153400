import React, { FC } from "react"

import { selectNewPassword, setNewPassword, useAppDispatch, useAppSelector } from "../../../store"

import { PasswordField } from "../../../../../components/Voiso"

export const NewPasswordField: FC = () => {
  const dispatch = useAppDispatch()
  const newPassword = useAppSelector(selectNewPassword)

  const handleNewPasswordChange = (value: string) => {
    dispatch(setNewPassword(value))
  }

  return (
    <PasswordField
      value={newPassword}
      onChange={handleNewPasswordChange}
      header="New password"
      name="new_password"
      placeholder="Enter new password"
    />
  )
}
