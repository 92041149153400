import styled from "styled-components"

export const PageWrapper = styled.div<{ isWide?: boolean }>`
  font-weight: 500;
  font-style: normal;
  display: flex;
  flex-direction: column;
  width: ${({ isWide }) => (isWide ? "600px" : "330px")};
  margin: 0% 0% 0% ${({ isWide }) => (isWide ? "40%" : "45%")};
  padding: 10% 0 0 0;
`

export const Header = styled.div`
  color: #222226;
  font-size: 38px;
  font-style: normal;
  font-weight: 600;
  line-height: 38px;
  margin-bottom: 25px;
`

export const ActionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`
