import React, { FC } from "react"

export const EyeIcon: FC = () => (
  <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_35445_331621)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.0238114 13.5632C1.34136 8.43942 6.34895 4.5 11.9924 4.5C17.6386 4.5 22.6587 8.43841 23.9766 13.5632C24.0797 13.9644 23.8381 14.3732 23.437 14.4764C23.0358 14.5795 22.627 14.3379 22.5238 13.9368C21.3807 9.49128 16.9711 6 11.9924 6C7.01861 6 2.61994 9.49027 1.47655 13.9368C1.37339 14.3379 0.964563 14.5795 0.5634 14.4764C0.162237 14.3732 -0.0793448 13.9644 0.0238114 13.5632ZM12.0002 10C10.1605 10 8.66815 11.4923 8.66815 13.332C8.66815 15.1717 10.1605 16.6641 12.0002 16.6641C13.8399 16.6641 15.3322 15.1717 15.3322 13.332C15.3322 11.4923 13.8399 10 12.0002 10ZM7.16815 13.332C7.16815 10.6639 9.33206 8.5 12.0002 8.5C14.6683 8.5 16.8322 10.6639 16.8322 13.332C16.8322 16.0002 14.6683 18.1641 12.0002 18.1641C9.33206 18.1641 7.16815 16.0002 7.16815 13.332Z"
        fill="#222226"
      />
    </g>
    <defs>
      <clipPath id="clip0_35445_331621">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
