import React, { ButtonHTMLAttributes, FC } from "react"

import { LinkButton, PrimaryButton, SecondaryButton } from "./Button.parts"

const ButtonComponentMapping = { primary: PrimaryButton, secondary: SecondaryButton, "link-button": LinkButton }

export interface IButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: "primary" | "secondary" | "link-button"
}

export const Button: FC<IButtonProps> = ({ variant = "primary", ...restProps }) => {
  const ButtonComponent = ButtonComponentMapping[variant]

  return <ButtonComponent {...restProps} />
}
