import React, { useEffect } from "react"

import { selectView, setView, useAppDispatch, useAppSelector } from "../store"
import type { LoginPageView } from "../types"

export const useViewComponent = (
  showNewPasswordView: boolean,
  viewMapping: Record<LoginPageView, React.ComponentType>
) => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(setView(showNewPasswordView ? "newPassword" : "login"))
  }, [])

  const currentView = useAppSelector(selectView)
  return currentView ? viewMapping[currentView] : React.Fragment
}
