import React, { VFC } from "react"

import { mount } from "@didlogic/web.app.core"
import { store } from "../pages/LoginPage/store"

import { Provider } from "react-redux"
import { WavixLoginPage } from "../pages/LoginPage/WavixLoginPage"

const WavixLoginWidget: VFC = () => (
  <Provider store={store}>
    <WavixLoginPage />
  </Provider>
)

mount(WavixLoginWidget, "wavix-auth-widget", true)
