import React, { FC } from "react"

import { selectPasswordConfirmation, setPasswordConfirmation, useAppDispatch, useAppSelector } from "../../../store"

import { PasswordField } from "../../../../../components/Voiso"

export const PasswordConfirmationField: FC = () => {
  const dispatch = useAppDispatch()
  const passwordConfirmation = useAppSelector(selectPasswordConfirmation)

  const handlePasswordConfirmationChange = (value: string) => {
    dispatch(setPasswordConfirmation(value))
  }

  return (
    <PasswordField
      value={passwordConfirmation}
      onChange={handlePasswordConfirmationChange}
      header="Confirm new password"
      name="password_confirmation"
      placeholder="Enter new password"
    />
  )
}
