import React from "react"

import { selectPassword, setPassword, useAppDispatch, useAppSelector } from "../../../store"

import { FormTextInput } from "../../../../../components/Wavix"

export const PasswordField = React.forwardRef<HTMLInputElement>((_props, ref) => {
  const dispatch = useAppDispatch()
  const password = useAppSelector(selectPassword)

  const handleInputChange = (value: string) => {
    dispatch(setPassword(value))
  }

  return (
    <FormTextInput
      type="password"
      value={password}
      name="password"
      ref={ref}
      placeholder="Password"
      onChange={(e) => handleInputChange(e.target.value)}
    />
  )
})
