import styled from "styled-components"

const red = "#bf080b"

export const FormFieldWrapper = styled.div`
  margin-bottom: 30px;
`

export const FormFieldLabel = styled.div`
  padding-bottom: 5px;
  color: #60606c;
`

export const FormFieldInputWrapper = styled.div<{ isError?: boolean }>`
  width: 328px;
  border-radius: 4px;
  border: 1px solid ${({ isError }) => (isError ? red : "#d6d6d8")};
`

export const FormTextInput = styled.input`
  width: 95%;
  height: 30px;
  border: none;
  -webkit-padding-start: 12px;
  -moz-padding-start: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  outline: none;
  background-clip: text;
  -webkit-background-clip: text !important;
  color: #60606c;
  &::-webkit-input-placeholder {
    font-size: 13px;
    font-weight: 400;
    color: #aeaeb7 !important;
  }
`

export const FormFieldErrorMessage = styled.div`
  width: 328px;
  color: ${red};
  font-size: 14px;
  font-weight: normal;
  margin-top: 5px;
`
