import React, { FC } from "react"

import { selectPasswordConfirmation, setPasswordConfirmation, useAppDispatch, useAppSelector } from "../../../store"

import { FormTextInput } from "../../../../../components/Wavix"

export const PasswordConfirmationField: FC = () => {
  const dispatch = useAppDispatch()
  const passwordConfirmation = useAppSelector(selectPasswordConfirmation)

  const handlePasswordConfirmationChange = (value: string) => {
    dispatch(setPasswordConfirmation(value))
  }

  return (
    <FormTextInput
      type="password"
      value={passwordConfirmation}
      onChange={(e) => handlePasswordConfirmationChange(e.target.value)}
      name="password_confirmation"
      placeholder="Confirm new password"
    />
  )
}
