import { createSlice } from "@reduxjs/toolkit"
import type { RootState } from "./store"
import type { LoginPageView } from "../types"

export interface LoginState {
  view?: LoginPageView
  email: string
  restoreEmail: string
  password: string
  code: string
  message: React.ReactNode
  is2FAInitiallyEnabled: boolean
  qrCode: string
  newPassword: string
  passwordConfirmation: string
}

const initialState: LoginState = {
  email: "",
  restoreEmail: "",
  password: "",
  code: "",
  message: "",
  is2FAInitiallyEnabled: false,
  qrCode: "",
  newPassword: "",
  passwordConfirmation: ""
}

export const loginReducer = createSlice({
  name: "login",
  initialState,
  reducers: {
    setEmail: (state, action) => {
      state.email = action.payload
    },
    setRestoreEmail: (state, action) => {
      state.restoreEmail = action.payload
    },
    setPassword: (state, action) => {
      state.password = action.payload
    },
    setCode: (state, action) => {
      state.code = action.payload
    },
    setMessage: (state, action) => {
      state.message = action.payload
    },
    setView: (state, action) => {
      state.view = action.payload
    },
    setIs2FAInitiallyEnabled: (state, action) => {
      state.is2FAInitiallyEnabled = action.payload
    },
    setQrCode: (state, action) => {
      state.qrCode = action.payload
    },
    setNewPassword: (state, action) => {
      state.newPassword = action.payload
    },
    setPasswordConfirmation: (state, action) => {
      state.passwordConfirmation = action.payload
    }
  },
  extraReducers: () => {}
})

export const {
  setCode,
  setEmail,
  setRestoreEmail,
  setPassword,
  setView,
  setIs2FAInitiallyEnabled,
  setQrCode,
  setMessage,
  setNewPassword,
  setPasswordConfirmation
} = loginReducer.actions

export const selectView = (state: RootState) => state.loginReducer.view
export const selectEmail = (state: RootState) => state.loginReducer.email
export const selectRestoreEmail = (state: RootState) => state.loginReducer.restoreEmail
export const selectPassword = (state: RootState) => state.loginReducer.password
export const selectCode = (state: RootState) => state.loginReducer.code
export const selectMessage = (state: RootState) => state.loginReducer.message
export const selectIs2FAInitiallyEnabled = (state: RootState) => state.loginReducer.is2FAInitiallyEnabled
export const selectQrCode = (state: RootState) => state.loginReducer.qrCode
export const selectNewPassword = (state: RootState) => state.loginReducer.newPassword
export const selectPasswordConfirmation = (state: RootState) => state.loginReducer.passwordConfirmation

export default loginReducer.reducer
