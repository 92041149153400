import React, { FC } from "react"

export const HideEyeIcon: FC = () => (
  <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M21.2356 1.9925C21.0408 1.99818 20.8559 2.07943 20.72 2.21906L2.21995 20.7191C2.14797 20.7882 2.09051 20.8709 2.05092 20.9625C2.01133 21.0541 1.99042 21.1527 1.98941 21.2525C1.98839 21.3523 2.0073 21.4513 2.04501 21.5436C2.08273 21.636 2.1385 21.7199 2.20906 21.7905C2.27962 21.8611 2.36355 21.9168 2.45593 21.9545C2.54831 21.9923 2.64729 22.0112 2.74707 22.0102C2.84685 22.0091 2.94542 21.9882 3.03702 21.9486C3.12861 21.9091 3.21139 21.8516 3.2805 21.7796L8.45823 16.6019C9.34258 17.5589 10.6004 18.1644 12.0002 18.1644C14.6652 18.1644 16.8352 15.9943 16.8352 13.3343C16.8352 11.9344 16.2298 10.6729 15.2727 9.78742L17.5266 7.53351C19.9525 8.95389 21.8346 11.2478 22.5256 13.9339C22.6106 14.2739 22.9152 14.4993 23.2502 14.4993C23.3102 14.4993 23.3748 14.4939 23.4348 14.4739C23.8398 14.3739 24.0798 13.9648 23.9748 13.5648C23.2049 10.5635 21.2308 8.03588 18.6389 6.42121L21.7805 3.27961C21.8887 3.17423 21.9626 3.03864 21.9924 2.89059C22.0223 2.74254 22.0068 2.58892 21.9479 2.44983C21.889 2.31075 21.7895 2.19268 21.6624 2.11108C21.5353 2.02948 21.3866 1.98815 21.2356 1.9925V1.9925ZM11.9953 4.49933C6.41034 4.49933 1.37562 8.30976 0.0256167 13.5648C-0.0793833 13.9648 0.160656 14.3739 0.565656 14.4739C0.965656 14.5789 1.37484 14.3389 1.47484 13.9339C2.63984 9.4089 7.16534 5.99933 11.9953 5.99933C12.8503 5.99933 13.695 6.1039 14.51 6.3089L15.7405 5.07941C14.5455 4.69941 13.2853 4.49933 11.9953 4.49933ZM12.0002 8.49933C9.33523 8.49933 7.17015 10.6693 7.17015 13.3343C7.17015 13.4343 7.17491 13.539 7.17991 13.639L9.07542 11.7445C9.38042 11.1795 9.85027 10.7096 10.4153 10.4046L12.3098 8.5091C12.2048 8.5041 12.1052 8.49933 12.0002 8.49933ZM14.2131 10.847C14.8984 11.4576 15.3352 12.3431 15.3352 13.3343C15.3352 15.1693 13.8402 16.6644 12.0002 16.6644C11.0129 16.6644 10.1282 16.2276 9.51781 15.5423L14.2131 10.847Z"
      fill="#222226"
    />
  </svg>
)
