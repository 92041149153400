import React, { useState } from "react"

import { PasswordModeToggle } from "./PasswordModeToggle"
import { FormFieldLabel, FormFieldWrapper, FormTextInput } from "../styles"
import { PasswordFieldInputWrapper } from "./PasswordField.parts"

export interface IPasswordFieldProps {
  value: string
  header?: React.ReactNode
  name: string
  placeholder?: string
  onChange: (value: string) => void
}

export const PasswordField = React.forwardRef<HTMLInputElement, IPasswordFieldProps>(
  ({ value, header = "Password", name, placeholder = "Enter password", onChange }, ref) => {
    const [showPassword, setShowPassword] = useState(false)

    const handleTogglerClick = () => {
      setShowPassword((isVisible) => !isVisible)
    }

    return (
      <FormFieldWrapper>
        <FormFieldLabel>{header}</FormFieldLabel>
        <PasswordFieldInputWrapper>
          <FormTextInput
            type={showPassword ? "text" : "password"}
            value={value}
            name={name}
            placeholder={placeholder}
            onChange={(e) => onChange(e.target.value)}
            ref={ref}
          />
          <PasswordModeToggle isPasswordVisible={showPassword} onClick={handleTogglerClick} />
        </PasswordFieldInputWrapper>
      </FormFieldWrapper>
    )
  }
)
