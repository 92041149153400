import React, { FC } from "react"

import { selectEmail, setEmail, useAppDispatch, useAppSelector } from "../../../store"

import { TextField } from "../../../../../components/Voiso"

export const EmailField: FC = () => {
  const dispatch = useAppDispatch()
  const email = useAppSelector(selectEmail)

  const handleEmailChange = (value: string) => {
    dispatch(setEmail(value))
  }

  return (
    <TextField value={email} onChange={handleEmailChange} header="Email" name="email" placeholder="Enter your email" />
  )
}
