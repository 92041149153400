import React, { VFC } from "react"

import { mount } from "@didlogic/web.app.core"
import { store } from "../pages/LoginPage/store"

import { Provider } from "react-redux"
import { VoisoLoginPage } from "../pages/LoginPage/VoisoLoginPage"

const VoisoLoginWidget: VFC = () => (
  <Provider store={store}>
    <VoisoLoginPage />
  </Provider>
)

mount(VoisoLoginWidget, "voiso-auth-widget", true)
