import React, { FC } from "react"

import { selectMessage, useAppSelector } from "../../store"

import { Message } from "../../../../components/shared"

export interface IWarningProps {
  style?: React.CSSProperties
}

export const Warning: FC<IWarningProps> = ({ style }) => {
  const message = useAppSelector(selectMessage)

  if (!message) return null

  return <Message style={style}>{message}</Message>
}
