import React from "react"

import { selectPassword, setPassword, useAppDispatch, useAppSelector } from "../../../store"

import { PasswordField as PasswordFieldBase } from "../../../../../components/Voiso"

export const PasswordField = React.forwardRef<HTMLInputElement>((_props, ref) => {
  const dispatch = useAppDispatch()
  const password = useAppSelector(selectPassword)

  const handlePasswordChange = (value: string) => {
    dispatch(setPassword(value))
  }

  return (
    <PasswordFieldBase
      value={password}
      onChange={handlePasswordChange}
      header="Password"
      name="password"
      placeholder="Enter password"
      ref={ref}
    />
  )
})
