import React, { FC } from "react"

import { useLogin } from "../../hooks/useLogin"

import { Button, Header, PageWrapper } from "../../../../components/Voiso"
import { Warning } from "../../components/Warning"
import { NewPasswordField } from "../components/NewPasswordField"
import { PasswordConfirmationField } from "../components/PasswordConfirmationField"

export const NewPasswordView: FC = () => {
  const { onSaveNewPassword } = useLogin()

  return (
    <PageWrapper>
      <Warning />
      <Header>Restore password</Header>
      <form>
        <NewPasswordField />
        <PasswordConfirmationField />
        <Button type="submit" onClick={onSaveNewPassword}>
          Save
        </Button>
      </form>
    </PageWrapper>
  )
}
