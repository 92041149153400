import React, { FC } from "react"

import { Warning } from "../../components/Warning"
import { Header, PageWrapper } from "../../../../components/Wavix"

export const LinkSentView: FC = () => (
  <PageWrapper>
    <Header>Restore password</Header>
    <Warning />
  </PageWrapper>
)
