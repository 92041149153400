import React, { VFC } from "react"

import { mount } from "@didlogic/web.app.core"
import { store } from "../pages/LoginPage/store"

import { Provider } from "react-redux"
import { WavixLoginPage } from "../pages/LoginPage/WavixLoginPage"

const WavixNewPasswordWidget: VFC = () => (
  <Provider store={store}>
    <WavixLoginPage showNewPasswordView />
  </Provider>
)

mount(WavixNewPasswordWidget, "wavix-new-password-widget", true)
