import React, { FC } from "react"

import { selectRestoreEmail, setRestoreEmail, useAppDispatch, useAppSelector } from "../../../store"

import { TextField } from "../../../../../components/Voiso"

export const RestoreEmailField: FC = () => {
  const dispatch = useAppDispatch()
  const email = useAppSelector(selectRestoreEmail)

  const handleRestoreEmailChange = (value: string) => {
    dispatch(setRestoreEmail(value))
  }

  return (
    <TextField
      value={email}
      onChange={handleRestoreEmailChange}
      header="Email"
      name="email"
      placeholder="Enter your email"
    />
  )
}
