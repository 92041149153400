import React, { FC } from "react"

import { selectEmail, setEmail, useAppDispatch, useAppSelector } from "../../../store"

import { FormTextInput } from "../../../../../components/Wavix"

export const EmailField: FC = () => {
  const dispatch = useAppDispatch()
  const email = useAppSelector(selectEmail)

  const handleInputChange = (value: string) => {
    dispatch(setEmail(value))
  }

  return (
    <FormTextInput
      type="text"
      value={email}
      name="email"
      placeholder="E-mail"
      onChange={(e) => handleInputChange(e.target.value)}
    />
  )
}
