import React, { FC } from "react"

import { useLogin } from "../../hooks/useLogin"

import { ActionsWrapper, Button, Header, PageWrapper } from "../../../../components/Voiso"
import { Warning } from "../../components/Warning"
import { RestoreEmailField } from "../components/RestoreEmailField"

export const RestorePasswordView: FC = () => {
  const { onRestorePassword, onRestorePasswordCancel } = useLogin()

  return (
    <PageWrapper>
      <Warning />
      <Header>Restore password</Header>
      <form>
        <RestoreEmailField />

        <ActionsWrapper>
          <Button type="submit" onClick={onRestorePassword}>
            Restore
          </Button>
          <Button variant="secondary" type="button" onClick={onRestorePasswordCancel}>
            Cancel
          </Button>
        </ActionsWrapper>
      </form>
    </PageWrapper>
  )
}
