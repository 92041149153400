import React, { FC } from "react"

import { useLogin } from "../../hooks/useLogin"

import { Button, Header, PageWrapper } from "../../../../components/Voiso"
import { Warning } from "../../components/Warning"
import { EmailField } from "../components/EmailField"
import { PasswordField } from "../components/PasswordField"

export const LoginView: FC = () => {
  const { passwordRef, onLoginSubmit, onShowRestorePassword } = useLogin()

  return (
    <PageWrapper>
      <Warning />
      <Header>Sign in</Header>
      <form>
        <EmailField />
        <PasswordField ref={passwordRef} />

        <Button variant="link-button" onClick={onShowRestorePassword} style={{ marginBottom: "15px" }}>
          Restore password
        </Button>

        <Button type="submit" onClick={onLoginSubmit}>
          Sign in
        </Button>
      </form>
    </PageWrapper>
  )
}
