import { brandedSessionApi } from "@didlogic/web.app.api"
import { configureReduxEnhancers } from "@didlogic/web.app.core"
import { configureStore } from "@reduxjs/toolkit"
import { setupListeners } from "@reduxjs/toolkit/query"
import thunkMiddleware from "redux-thunk"

import loginReducer from "./loginSlice"

export const store = configureStore({
  reducer: {
    [brandedSessionApi.reducerPath]: brandedSessionApi.reducer,
    loginReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(brandedSessionApi.middleware).concat(thunkMiddleware),
  enhancers: (enhancers) => configureReduxEnhancers(enhancers)
})

setupListeners(store.dispatch)

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
