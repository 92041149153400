import React, { FC } from "react"

import {
  FormFieldErrorMessage,
  FormFieldInputWrapper,
  FormFieldLabel,
  FormFieldWrapper,
  FormTextInput
} from "../styles"

export interface ITextFieldProps {
  value: string
  header: React.ReactNode
  name: string
  placeholder?: string
  errorMessage?: string
  onChange: (value: string) => void
}

export const TextField: FC<ITextFieldProps> = ({ value, header, name, placeholder, errorMessage, onChange }) => (
  <FormFieldWrapper>
    <FormFieldLabel>{header}</FormFieldLabel>
    <FormFieldInputWrapper isError={Boolean(errorMessage)}>
      <FormTextInput
        type="text"
        value={value}
        name={name}
        placeholder={placeholder}
        onChange={(e) => onChange(e.target.value)}
      />
    </FormFieldInputWrapper>
    {errorMessage && <FormFieldErrorMessage>{errorMessage}</FormFieldErrorMessage>}
  </FormFieldWrapper>
)
