import React, { FC } from "react"

import { selectQrCode, useAppSelector } from "../../store"

export interface IQrCodeProps {
  className?: string
}

export const QrCode: FC<IQrCodeProps> = ({ className }) => {
  const qrCode = useAppSelector(selectQrCode)

  return qrCode ? (
    <div className={className}>
      <img src={qrCode} />
    </div>
  ) : null
}
