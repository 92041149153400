import styled from "styled-components"

import { QrCode } from "../../../components/QrCode"

export const Add2FAViewWrapper = styled.div`
  display: flex;
  gap: 40px;
`

export const InfoPanel = styled.div`
  max-width: 350px;
`

export const Description = styled.div`
  line-height: 22px;
  margin: 25px 0;
`
// add negative margin to align 3rd party image with white paddings included
export const StyledQrCode = styled(QrCode)`
  margin-top: -20px;
`
