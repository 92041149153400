import styled from "styled-components"

export const Add2FAViewWrapper = styled.div`
  display: flex;
  gap: 40px;
`

export const InfoPanel = styled.div`
  max-width: 380px;
`

export const Description = styled.div`
  margin: 25px 0 30px;
  line-height: 25px;
`
