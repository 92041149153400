import React, { FC } from "react"

import { Header, PageWrapper } from "../../../../components/Voiso"
import { Warning } from "../../components/Warning"

export const LinkSentView: FC = () => (
  <PageWrapper>
    <Header>Restore password</Header>
    <Warning style={{ marginTop: 0 }} />
  </PageWrapper>
)
