import styled from "styled-components"

import { FormTextInput } from "../../../../../components/Wavix"

export const StyledCodeField = styled.div`
  ${FormTextInput} {
    margin-bottom: 10px;
  }

  margin-bottom: 30px;
`
