import React, { ButtonHTMLAttributes, FC } from "react"

import { SecondaryButton, PrimaryButton } from "./Button.parts"
import { ArrowIcon } from "../icons"

// let's leave this mapping for future extensions
const ButtonComponentMapping = { secondary: SecondaryButton }

export interface IButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: "primary" | "secondary"
  className?: string
}

export const Button: FC<IButtonProps> = ({ variant = "primary", children, ...restProps }) => {
  if (variant === "primary")
    return (
      <PrimaryButton {...restProps}>
        {children} <ArrowIcon />
      </PrimaryButton>
    )

  const ButtonComponent = ButtonComponentMapping[variant]

  return <ButtonComponent {...{ ...restProps, children }} />
}
