import React, { FC } from "react"

import { selectRestoreEmail, setRestoreEmail, useAppDispatch, useAppSelector } from "../../../store"

import { FormTextInput } from "../../../../../components/Wavix"

export const RestoreEmailField: FC = () => {
  const dispatch = useAppDispatch()
  const email = useAppSelector(selectRestoreEmail)

  const handleInputChange = (value: string) => {
    dispatch(setRestoreEmail(value))
  }

  return (
    <FormTextInput
      type="text"
      value={email}
      name="email"
      placeholder="E-mail"
      onChange={(e) => handleInputChange(e.target.value)}
    />
  )
}
