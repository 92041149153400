import React, { FC } from "react"

import { EyeIcon, HideEyeIcon } from "./Icons"
import { StyledToggler } from "./PasswordModeToggle.parts"

interface IPasswordModeToggleProps {
  isPasswordVisible: boolean
  onClick: () => void
}

export const PasswordModeToggle: FC<IPasswordModeToggleProps> = ({ isPasswordVisible, onClick }) => (
  <StyledToggler onClick={onClick}>{isPasswordVisible ? <HideEyeIcon /> : <EyeIcon />}</StyledToggler>
)
