import React, { FC } from "react"

import { selectCode, setCode, useAppDispatch, useAppSelector } from "../../../store"

import { TextField } from "../../../../../components/Voiso"

export const CodeField: FC = () => {
  const dispatch = useAppDispatch()
  const code = useAppSelector(selectCode)

  const handleCodeChange = (value: string) => {
    dispatch(setCode(value))
  }

  return (
    <TextField
      value={code}
      onChange={handleCodeChange}
      header="2FA Code"
      name="confirmation_code"
      placeholder="Enter code"
    />
  )
}
