import React, { FC } from "react"

import { LoginView } from "./views/LoginView"
import { CodeView } from "./views/CodeView"
import { Add2FAView } from "./views/Add2FAView"
import { RestorePasswordView } from "./views/RestorePasswordView"
import { LinkSentView } from "./views/LinkSentView"
import { NewPasswordView } from "./views/NewPasswordView"
import { useViewComponent } from "../hooks/useViewComponent"

const ViewMapping = {
  login: LoginView,
  code: CodeView,
  add2FA: Add2FAView,
  restorePassword: RestorePasswordView,
  linkSent: LinkSentView,
  newPassword: NewPasswordView
}

interface IWavixLoginPageProps {
  showNewPasswordView?: boolean
}

export const WavixLoginPage: FC<IWavixLoginPageProps> = ({ showNewPasswordView = false }) => {
  const ViewComponent = useViewComponent(showNewPasswordView, ViewMapping)

  return <ViewComponent />
}
