import styled from "styled-components"

export const PrimaryButton = styled.button`
  border-radius: 4px;
  border: 2px solid #fff;
  background: none;
  display: flex;
  height: 46px;
  padding-left: 20px;
  padding-right: 20px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  cursor: pointer;
  color: #fff;
  font-size: 14px;
  font-family: Helvetica Neue;
  font-style: normal;
  font-weight: 700;
  line-height: 160%;
  gap: 9px;

  &:hover {
    background-color: #6acc83;
  }

  svg {
    width: 20px;
    height: 20px;
    flex-shrink: 0;
  }
`

export const SecondaryButton = styled.button`
  border: none;
  color: #fff;
  background-color: inherit;
  padding: 0;
  font-size: 16px;
  font-family: Helvetica Neue;
  cursor: pointer;
`
