import React, { FC } from "react"

import { useLogin } from "../../hooks/useLogin"

import { Button, Header, PageWrapper, ActionsWrapper } from "../../../../components/Wavix"
import { Warning } from "../../components/Warning"
import { CodeField } from "../components/CodeField"

export const CodeView: FC = () => {
  const { onCodeSubmit, onCodeCancel } = useLogin()

  return (
    <PageWrapper>
      <Warning />
      <Header>Enter 2FA Code</Header>
      <form>
        <CodeField />

        <ActionsWrapper>
          <Button type="submit" onClick={onCodeSubmit}>
            Verify
          </Button>
          <Button variant="secondary" type="button" onClick={onCodeCancel}>
            Cancel
          </Button>
        </ActionsWrapper>
      </form>
    </PageWrapper>
  )
}
