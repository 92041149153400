import React, { FC } from "react"

import { selectCode, setCode, useAppDispatch, useAppSelector } from "../../../store"

import { FormTextInput } from "../../../../../components/Wavix"
import { StyledCodeField } from "./CodeField.parts"

export const CodeField: FC = () => {
  const dispatch = useAppDispatch()
  const code = useAppSelector(selectCode)

  const handleInputChange = (value: string) => {
    dispatch(setCode(value))
  }

  return (
    <StyledCodeField>
      <FormTextInput
        type="text"
        value={code}
        name="confirmation_code"
        placeholder="2FA Code"
        onChange={(e) => handleInputChange(e.target.value)}
      />
    </StyledCodeField>
  )
}
