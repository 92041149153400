import React, { useRef } from "react"

import { BRANDED_SESSION_API } from "@didlogic/web.app.api"
import { deriveErrorMessage } from "../../../utils/error"
import { validateNewPassword } from "../../../utils/validation"
import {
  selectCode,
  selectEmail,
  selectIs2FAInitiallyEnabled,
  selectNewPassword,
  selectPassword,
  selectPasswordConfirmation,
  selectRestoreEmail,
  setCode,
  setEmail,
  setIs2FAInitiallyEnabled,
  setMessage,
  setPassword,
  setQrCode,
  setRestoreEmail,
  setView,
  useAppDispatch,
  useAppSelector
} from "../store"

export const useLogin = () => {
  const dispatch = useAppDispatch()

  const email = useAppSelector(selectEmail)
  const restoreEmail = useAppSelector(selectRestoreEmail)
  const password = useAppSelector(selectPassword)
  const mfa_code = useAppSelector(selectCode)
  const is2FAInitiallyEnabled = useAppSelector(selectIs2FAInitiallyEnabled)
  const new_password = useAppSelector(selectNewPassword)
  const password_confirmation = useAppSelector(selectPasswordConfirmation)

  const [startSession] = BRANDED_SESSION_API.postBrandedSession.useMutation()
  const [sendReminderEmail] = BRANDED_SESSION_API.postRemindPassword.useMutation()
  const [fetch2FAStatus] = BRANDED_SESSION_API.postGoogle2FAStatus.useMutation()
  const [generate2FAQrCode] = BRANDED_SESSION_API.postGenerateGoogle2FA.useMutation()
  const [changePassword] = BRANDED_SESSION_API.postChangePassword.useMutation()

  const passwordRef = useRef<HTMLInputElement>(null)

  const showLoginView = () => {
    dispatch(setPassword(""))
    dispatch(setMessage(""))
    dispatch(setIs2FAInitiallyEnabled(false))
    dispatch(setQrCode(""))
    dispatch(setView("login"))
  }

  const showCodeView = () => {
    dispatch(setCode(""))
    dispatch(setMessage(""))
    dispatch(setView("code"))
  }

  const navigateForwardToAdd2FAView = async (email: string, password: string) => {
    const response = await generate2FAQrCode({
      email,
      password
    })

    if ("data" in response) {
      const { qr_base64 } = response.data
      dispatch(setQrCode(qr_base64))
    } else {
      const errorMessage = deriveErrorMessage(response.error)
      dispatch(setMessage(errorMessage))
    }

    dispatch(setView("add2FA"))
  }

  const navigateBackToAdd2FAView = () => {
    dispatch(setView("add2FA"))
  }

  const showRestorePasswordView = () => {
    dispatch(setRestoreEmail(email))
    dispatch(setMessage(""))
    dispatch(setView("restorePassword"))
  }

  const showLinkSentView = () => {
    dispatch(
      setMessage(
        <>
          Password reset link sent to <a href={`mailto:${restoreEmail}`}>{restoreEmail}</a>
        </>
      )
    )
    dispatch(setView("linkSent"))
  }

  const onLoginSubmit = async (e: React.FormEvent) => {
    e.preventDefault()

    const response = await fetch2FAStatus({
      email,
      password
    })

    if ("data" in response) {
      const { google_2fa_status } = response.data
      dispatch(setIs2FAInitiallyEnabled(google_2fa_status))
      dispatch(setMessage(""))

      if (google_2fa_status) {
        showCodeView()
      } else {
        navigateForwardToAdd2FAView(email, password)
      }
    } else {
      const errorMessage = deriveErrorMessage(response.error)
      dispatch(setMessage(errorMessage))

      dispatch(setPassword(""))
      if (passwordRef.current) {
        passwordRef.current.focus()
      }
    }
  }

  const onAdd2FASubmit = (e: React.FormEvent) => {
    e.preventDefault()
    dispatch(setMessage(""))

    showCodeView()
  }

  const onCodeSubmit = async (e: React.FormEvent) => {
    e.preventDefault()

    const response = await startSession({
      email,
      password,
      mfa_code
    })

    if ("data" in response) {
      window.location.href = "/purchases/new"
    } else {
      const errorMessage = deriveErrorMessage(response.error)
      dispatch(setMessage(errorMessage))
    }
  }

  const onRestorePassword = async (e: React.FormEvent) => {
    e.preventDefault()

    const response = await sendReminderEmail({ email: restoreEmail })

    if ("data" in response) {
      showLinkSentView()
    } else {
      const errorMessage = deriveErrorMessage(response.error)
      dispatch(setMessage(errorMessage))
    }
  }

  const onSaveNewPassword = async (e: React.FormEvent) => {
    e.preventDefault()

    const validationResult = validateNewPassword(new_password, password_confirmation)
    if (!validationResult.isValid) {
      dispatch(setMessage(validationResult.errorMessage))
      return
    }

    const urlParams = new URLSearchParams(window.location.search)
    const key = urlParams.get("key") ?? ""

    const response = await changePassword({
      key,
      password: new_password,
      password_confirmation
    })

    if ("data" in response) {
      const { google_2fa_status, email } = response.data
      dispatch(setIs2FAInitiallyEnabled(google_2fa_status))
      dispatch(setMessage(""))
      dispatch(setEmail(email))
      dispatch(setPassword(new_password))

      if (google_2fa_status) {
        showCodeView()
      } else {
        navigateForwardToAdd2FAView(email, new_password)
      }
    } else {
      const errorMessage = deriveErrorMessage(response.error)
      dispatch(setMessage(errorMessage))
    }
  }

  const onAdd2FACancel = (e: React.FormEvent) => {
    e.preventDefault()

    showLoginView()
  }

  const onCodeCancel = (e: React.FormEvent) => {
    e.preventDefault()

    if (is2FAInitiallyEnabled) {
      showLoginView()
    } else {
      navigateBackToAdd2FAView()
    }
  }

  const onShowRestorePassword = (e: React.FormEvent) => {
    e.preventDefault()

    showRestorePasswordView()
  }

  const onRestorePasswordCancel = (e: React.FormEvent) => {
    e.preventDefault()

    showLoginView()
  }

  return {
    passwordRef,
    onLoginSubmit,
    onAdd2FASubmit,
    onCodeSubmit,
    onShowRestorePassword,
    onRestorePassword,
    onSaveNewPassword,
    onAdd2FACancel,
    onCodeCancel,
    onRestorePasswordCancel
  }
}
