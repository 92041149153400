// formik+yup is overkill for this form
export const validateNewPassword = (
  password: string,
  confirmation: string
): { isValid: false; errorMessage: string } | { isValid: true } => {
  if (!password) return { isValid: false, errorMessage: "Password can't be blank" }
  if (password !== confirmation) return { isValid: false, errorMessage: "Passwords do not match" }
  if (!/^(?=.*\d)(?=.*[A-Za-z])(?!.*[^\x00-\x7F])(?!.* ).{8,}$/.test(password))
    return {
      isValid: false,
      errorMessage: "Password must contain minimum eight characters, at least one letter and one number"
    }
  return { isValid: true }
}
