import React, { FC } from "react"

import { useLogin } from "../../hooks/useLogin"

import { Warning } from "../../components/Warning"
import { ActionsWrapper, Button, Header, PageWrapper } from "../../../../components/Wavix"
import { EmailField } from "../components/EmailField"
import { PasswordField } from "../components/PasswordField"

export const LoginView: FC = () => {
  const { passwordRef, onLoginSubmit, onShowRestorePassword } = useLogin()

  return (
    <PageWrapper>
      <Warning />
      <Header>Log in</Header>
      <form>
        <EmailField />
        <PasswordField ref={passwordRef} />

        <ActionsWrapper style={{ marginTop: "30px" }}>
          <Button type="submit" onClick={onLoginSubmit}>
            Log in
          </Button>

          <Button variant="secondary" onClick={onShowRestorePassword}>
            Restore password
          </Button>
        </ActionsWrapper>
      </form>
    </PageWrapper>
  )
}
