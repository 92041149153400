import React, { FC } from "react"

import { selectNewPassword, setNewPassword, useAppDispatch, useAppSelector } from "../../../store"

import { FormTextInput } from "../../../../../components/Wavix"

export const NewPasswordField: FC = () => {
  const dispatch = useAppDispatch()
  const newPassword = useAppSelector(selectNewPassword)

  const handleNewPasswordChange = (value: string) => {
    dispatch(setNewPassword(value))
  }

  return (
    <FormTextInput
      type="password"
      value={newPassword}
      onChange={(e) => handleNewPasswordChange(e.target.value)}
      name="new_password"
      placeholder="Enter new password"
    />
  )
}
