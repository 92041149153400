import styled from "styled-components"

export const PrimaryButton = styled.button`
  display: block;
  line-height: 32px;
  transition: 0.3s;
  width: 100%;
  height: 32px;
  border-radius: 4px;
  border: none;
  background: #3c7be1;
  color: #ffffff;
  font-size: 14px;
  font-weight: bold;
  font-family: "GraphikLight";
  cursor: pointer;

  &:hover {
    background-color: #0e141d;
  }
`

export const SecondaryButton = styled.button`
  display: block;
  line-height: 32px;
  transition: 0.3s;
  width: 100%;
  height: 32px;
  border-radius: 4px;
  border: none;
  background: #ffffff;
  color: #60606c;
  font-size: 14px;
  font-weight: bold;
  font-family: "GraphikLight";
  cursor: pointer;
`

export const LinkButton = styled.button`
  border: none;
  color: #3c7be1;
  background-color: inherit;
  padding: 0;
  font-size: 14px;
  font-family: "GraphikLight";
  cursor: pointer;
`
